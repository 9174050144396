import React from 'react';
import {
  Stack, Box, Heading, Text, theme,
} from '@chakra-ui/core';

import Layout from '../components/display/layout';
import SEO from '../components/seo';
import Card from '../components/display/card';

import { HomeText } from '../components/textfiles/contenttext';

const HomePage = () => (
  <Layout>
    <SEO title="Home" />
    <Heading
      as="h1"
      size="lg"
      m={1}
      style={{ color: theme.colors.green[500] }}
    >
      {HomeText.title}
    </Heading>
    <Stack spacing={8} align="center">
      <Text p={3} fontSize="lg">{HomeText.welcome}</Text>
    </Stack>
    <Stack spacing={5} align="center">
      <Card style={{ padding: '12px' }}>
        {HomeText.welcome}
      </Card>
    </Stack>
    <Stack spacing={8} align="center">
      <Card style={{ padding: '12px' }}>
        {HomeText.description}
      </Card>
    </Stack>
  </Layout>
);

export default HomePage;
